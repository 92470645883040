/**
 * @namespace components
 */

import React from 'react';
import PropTypes from 'prop-types';

import { miniGazelle } from '../../../helpers';

const getIsAriaHidden = ({ className, currentSlide, slideCount }) => {
    return className.includes('slick-next') ? currentSlide + 1 === slideCount :
           className.includes('slick-prev') ? currentSlide === 0 :
           false;
};

const AccessibleCarouselArrow = props => {
    const print = 1;
    const { i, currentSlide, className } = props;
    
    const ariaLabel = className.includes('slick-next')
        ? 'next-slide'
        : className.includes('slick-prev')
        ? 'previous-slide'
        : `${miniGazelle.label('slide', true)}: ${print}`;
        
    return (
        <button
            {...props}
            aria-hidden={getIsAriaHidden(props)}
            aria-label={ariaLabel}
        />
    );
};

export default AccessibleCarouselArrow;

AccessibleCarouselArrow.propTypes = {
    i: PropTypes.number.isRequired,
    currentSlide: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
};
